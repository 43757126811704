@import url('https://fonts.googleapis.com/css?family=Unbounded');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: unbounded, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    
  --offWhite: #f1f1f1;
  --brightYellow: #fff47b;
}

p, ul, li, b, i, a {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Text variations */
.oT {
    color: var(--brightYellow);
}

.scaleT {
  display: inline-block;
  transform: scale(1.0);
  transition-duration: 0.15s;
  
  text-shadow: inherit;
}
.scaleT:hover {
  transform: scale(1.1);
  
  text-shadow: 1px 1px 5px rgba(0,0,0,0.7);
}

/* Text button */

.btn-text {
  text-decoration: none;
  cursor: pointer;
  
  user-select: none;
  
  color: var(--offWhite);
  transition-duration: 0.15s;
}

.btn-text a:visited,
.btn-text a:link {
    color: var(--offWhite);
}

.btn-text:hover {
    color: #d1d1d1;
}
.btn-text:active {
    color: var(--brightYellow);
    transition-duration: 0.05s;
}

/* width */
.scrollbar-thin::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar-thin::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.3);
  border-radius: 100px;
}

/* Handle */
.scrollbar-thin::-webkit-scrollbar-thumb {
  background: var(--brightYellow);
  border-radius: 100px;
  outline: 1px solid rgba(255, 99, 71, 0.3);
}

/* Handle on hover */
.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  outline: 1px solid rgba(255, 99, 71, 0.8);
}