.hollowButton a {
    height: 60px;
    padding: 0 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border-radius: 60px;
    border: 2px solid #f1f1f1;
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.35);

    color: #f1f1f1;
    font-size: 20px;
    text-decoration: none;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);

    backdrop-filter: blur(1px);

    transition-duration: 0.15s;
}

.hollowButton a:visited,
.hollowButton a:link {
    color: #f1f1f1;
}

.hollowButton a:hover {
    color: #d1d1d1;
}