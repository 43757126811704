#background {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: auto;
    height: 100vh;

    background: url('./assets/background_mintGreen_noise.png');
    background-size: cover;
    background-position: center;
}

#hero {
    display: flex;
    width: auto;
    height: 400px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #f1f1f1;
    /* background: #ff0000; */
}

.title {
    font-size: 48px;
    line-height: 54px;
    margin: 0;
    
    text-shadow: none;
}

.subTitle {
    margin: 20px 0;
    font-size: 24px;
}

.circleBlue {
    position: fixed;
    width: 80vw;
    height: 80vw;

    outline: 2px solid #2749ba;
    border-radius: 1000px;

    pointer-events: none;
}

.circleBlue2 {
    position: fixed;
    width: 81vw;
    height: 81vw;

    outline: 2px solid #2749ba90;
    border-radius: 1000px;

    pointer-events: none;
}

.circleBlue3 {
    position: fixed;
    width: 82vw;
    height: 82vw;

    outline: 2px solid #2749ba40;
    border-radius: 1000px;

    pointer-events: none;
}