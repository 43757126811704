.homeButton a {
    position: absolute;
    top: top;
    left: left;
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border-radius: 60px;
    border: 2px solid var(--offWhite);
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.35);

    text-decoration: 'none';
    color: var(--offWhite);

    transition-duration: 0.15s;

    opacity: 0.5;
}

.homeButton a:visited,
.homeButton a:link {
    color: #f1f1f1;
}

.homeButton a:hover {
    color: #d1d1d1;
    opacity: 1.0;
}